
























import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  props: {
    publicKeyCredentialId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      error: null as null | Error,
      loading: false,
      showModal: false,
    };
  },
  computed: {
    ...mapGetters({
      account: 'account/Get',
    }),
  },
  methods: {
    async deleteCredential(attempt: any) {
      this.loading = true;

      if (!attempt) {
        this.showModal = true;
        return;
      }

      this.showModal = false;

      await this.$store.dispatch('account/mfa/DeletePublicKeyCredential', {
        TOKEN: attempt.id.concat(attempt.secret),
        PUBLIC_KEY_CREDENTIAL_ID: this.publicKeyCredentialId,
      }).catch((e) => { this.error = e; this.loading = false; });
    },
  },
});
