











































import Vue from 'vue';
import { mapGetters } from 'vuex';
import { AxiosError } from 'axios';
import PublicKeyCredentialAttestationForm from '@/components/account/PublicKeyCredentialAttestationForm.vue';
import PublicKeyCredentialDeleteButton from '@/components/account/PublicKeyCredentialDeleteButton.vue';

export default Vue.extend({
  components: {
    PublicKeyCredentialAttestationForm,
    PublicKeyCredentialDeleteButton,
  },
  data() {
    return {
      error: null as Error|null,
      loading: true,
    };
  },
  computed: {
    ...mapGetters({
      list: 'account/mfa/PublicKeyCredentials',
    }),
  },
  mounted() {
    this.$store.dispatch('account/mfa/ListPublicKeyCredentials')
      .catch((e: AxiosError) => { this.error = e; })
      .then(() => { this.loading = false; });
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
