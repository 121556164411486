












































import Vue from 'vue';
import { mapGetters } from 'vuex';

function arrayToBase64String(a: Uint8Array): string {
  return btoa(String.fromCharCode(...a));
}

function base64url2base64(input: string): string {
  let output = input
    .replace(/-/g, '+')
    .replace(/_/g, '/');
  const pad = output.length % 4;
  if (pad) {
    if (pad === 1) {
      throw new Error('InvalidLengthError: Input base64url string is the wrong length to determine padding');
    }
    output += new Array(5 - pad).join('=');
  }
  return output;
}

export default Vue.extend({
  data() {
    return {
      error: null as null | Error,
      form: {
        display_name: '',
      },
      loading: false,
      showModal: false,
      validation: {
        name: null,
      },
    };
  },
  computed: {
    ...mapGetters({
      account: 'account/Get',
    }),
  },
  methods: {
    async attest(attempt: any): Promise<void> {
      this.loading = true;
      this.error = null;

      if (!attempt) {
        this.showModal = true;
        return;
      }

      this.showModal = false;

      // 1. Request Credential Creation Options

      const data = await this.$store.dispatch('account/mfa/GenerateCreationOptions', {
        TOKEN: attempt.id.concat(attempt.secret),
        form: this.form,
      }).catch((e) => { this.error = e; this.loading = false; });

      if (!data) return;

      const publicKey = data.data;
      const { id, secret } = data.meta.auth;

      publicKey.challenge = Uint8Array.from(
        atob(
          base64url2base64(publicKey.challenge),
        ),
        (c) => c.charCodeAt(0),
      );

      publicKey.user.id = Uint8Array.from(
        atob(publicKey.user.id),
        (c) => c.charCodeAt(0),
      );

      if (publicKey.excludeCredentials) {
        publicKey.excludeCredentials = publicKey.excludeCredentials.map((d: any) => {
          // eslint-disable-next-line no-param-reassign
          d.id = Uint8Array.from(window.atob(base64url2base64(d.id)), (c) => c.charCodeAt(0));
          return d;
        });
      }

      // 2. Create Credentials

      const credential: any = await navigator.credentials.create({ publicKey })
        .catch((e) => {
          this.loading = false;
          console.error(e);
        });

      if (!credential) return;

      const publicKeyCredential = {
        id: credential.id,
        type: credential.type,
        rawId: arrayToBase64String(new Uint8Array(credential.rawId)),
        response: {
          clientDataJSON: arrayToBase64String(
            new Uint8Array(credential.response.clientDataJSON),
          ),
          attestationObject: arrayToBase64String(
            new Uint8Array(credential.response.attestationObject),
          ),
        },
      };

      // 3. Verify Created Credentials and save

      await this.$store.dispatch('account/mfa/VerifyCreationResponse', {
        form: publicKeyCredential,
        query: { id, secret },
      }).catch((e) => { this.error = e; });

      this.loading = false;

      if (!this.error) this.form.display_name = '';
    },
  },
});
